(function ($) {
    $.fn.fixedHeader = function (options) {
        const config = {
            top: 0,
            zIndex: 1000,
            fixedContainerClasses: 'container-thead',
            responsiveContainer($table) {
                return $table.parent();
            },
            scrollContainer() {
                return $(window);
            },
            optimization: {
                // троттлинг, ms при resize scroll контейнера
                resize: 100,
                // проверка изменились ли размеры thead
                check: 150,
            },
            onAfterFixed($copyTableHeadContainer) {
            },
            onAfterReDrawHead($copyTableHeadContainer) {
            },
            // скрывать, если до конца родительского контейнера, в пикселях
            bottom_end: 50,
        };
        if (options) {
            $.extend(config, options);
        }
        const $scrollContainer = config.scrollContainer();

        function getTheadChildNodesWidth($thead) {
            const childNodes = [];
            $thead.find('tr')
                .each((iTr, elemTr) => {
                    childNodes[iTr] = [];
                    $(elemTr)
                        .find('th')
                        .each((iTh, elemTh) => {
                            childNodes[iTr][iTh] = getCoords(elemTh).width;
                        });
                });
            return childNodes;
        }

        function getCopyThead($thead) {
            const columns = [];
            $thead.find('tr')
                .each((iTr, elemTr) => {
                    columns[iTr] = [];
                    $(elemTr)
                        .find('th')
                        .each((iTh, elemTh) => {
                            const browserName = navigator.userAgent.toLowerCase();
                            const isGoogleChrome = browserName.includes('chrome');
                            const elemThWidth = (!isGoogleChrome) ? $(elemTh).width() : getCoords(elemTh).width;
                            columns[iTr][iTh] = {
                                css: {
                                    'font-size': $(elemTh).css('font-size'),
                                    'font-family': $(elemTh).css('font-family'),
                                    width: elemThWidth,
                                    'min-width': elemThWidth,
                                    'max-width': elemThWidth,
                                    color: $(elemTh).css('color'),
                                    'background-color': $(elemTh).css('background-color'),
                                    'vertical-align': $(elemTh).css('vertical-align'),
                                },
                            };
                        });
                });
            const $copyThead = $('<thead>').html($thead.html());
            $copyThead.find('tr')
                .each((iTr, elemTr) => {
                    $(elemTr)
                        .find('th')
                        .each((iTh, elemTh) => {
                            const cell = columns[iTr][iTh];
                            $(elemTh)
                                .css(cell.css);
                        });
                });
            return $copyThead;
        }

        const throttle = function (func, ms) {
            let isThrottled = false;
            let savedArgs;
            let savedThis;

            function wrapper() {
                if (isThrottled) {
                    savedArgs = arguments;
                    savedThis = this;
                    return;
                }
                func.apply(this, arguments);
                isThrottled = true;
                setTimeout(() => {
                    isThrottled = false;
                    if (savedArgs) {
                        wrapper.apply(savedThis, savedArgs);
                        savedArgs = savedThis = null;
                    }
                }, ms);
            }

            return wrapper;
        };

        function createCopyTableHeadContainer($table) {
            const classList = $table.attr('class');
            const $copyThead = getCopyThead($table.find('thead'));
            const $copyTable = createCopyTable($copyThead, classList);
            const $tableWrapper = createTableWrapper();
            $tableWrapper.append($copyTable);
            $table.parent().append($tableWrapper);
            return $tableWrapper;
        }

        function getScrollTop(scrolledObject) {
            return $.isWindow(scrolledObject)
                ? window.pageYOffset || document.documentElement.scrollTop
                : $(scrolledObject)
                    .scrollTop();
        }

        function getCoords(elem) {
            const {body} = document;
            const docEl = document.documentElement;
            const scrollTop = getScrollTop($scrollContainer[0]);
            const box = elem.getBoundingClientRect();
            const clientTop = docEl.clientTop || body.clientTop || 0;
            const top = box.top + scrollTop - clientTop;
            return {
                top,
                height: box.height,
                width: box.width,
                scrollTop,
            };
        }

        function createTableWrapper() {
            const $container = $('<div>', {class: config.fixedContainerClasses});
            $container.css({
                position: 'fixed',
                overflow: 'hidden',
                top: config.top,
                display: 'none',
                'z-index': config.zIndex,
            });
            return $container;
        }

        function createCopyTable($thead, classList) {
            const $copyTable = $('<table>', {class: classList});
            $copyTable
                .css({
                    'margin-bottom': 0,
                })
                .html($thead);
            return $copyTable;
        }

        function getTablePosition(tableElement) {
            const tablePosition = getCoords(tableElement);
            const theadElement = tableElement.querySelectorAll('thead')[0];
            tablePosition.theadHeight = getCoords(theadElement).height;
            return tablePosition;
        }

        function showHideThead(tableElement, $copyTableHeadContainer) {
            const tablePosition = getTablePosition(tableElement);
            const {scrollTop} = tablePosition;
            if ((tablePosition.top + config.top - config.bottom_end) < scrollTop
                && (tablePosition.top + tablePosition.height - tablePosition.theadHeight - config.bottom_end) > scrollTop
            ) {
                if (!$copyTableHeadContainer.is(':visible')) {
                    $copyTableHeadContainer.show();
                    config.onAfterFixed($copyTableHeadContainer);
                }
            } else {
                $copyTableHeadContainer.hide();
            }
        }

        function translateCopyTableX($responsiveContainer, $copyTable) {
            const responsiveContainer = $responsiveContainer[0];
            const scrollLeft = responsiveContainer.pageXOffset || responsiveContainer.scrollLeft;
            $copyTable.css('transform', `translateX(-${scrollLeft}px)`);
        }

        function resizeTableHeadContainer($responsiveContainer, $copyTableHeadContainer) {
            const responsiveContainer = $responsiveContainer[0];
            const width = responsiveContainer.offsetWidth;
            $copyTableHeadContainer.css('width', width);
        }

        function isEqualsArrays(a1, a2) {
            if (a1.length !== a2.length) {
                return false;
            }
            for (const i in a1) {
                if (a1[i] instanceof Array && a2[i] instanceof Array) {
                    if (!isEqualsArrays(a1[i], a2[i])) {
                        return false;
                    }
                } else if (a1[i] != a2[i]) {
                    return false;
                }
            }
            return true;
        }

        return this.each(function () {
            const $table = $(this);
            const $thead = $table.find('thead');
            const $responsiveContainer = config.responsiveContainer($table);
            const $copyTableHeadContainer = createCopyTableHeadContainer($table);
            const tableElement = $table[0];
            let lastTheadChildNodesWidth = getTheadChildNodesWidth($copyTableHeadContainer.find('thead'));
            resizeTableHeadContainer($responsiveContainer, $copyTableHeadContainer);
            translateCopyTableX($responsiveContainer, $copyTableHeadContainer.find('table'));
            showHideThead(tableElement, $copyTableHeadContainer);
            config.onAfterReDrawHead($copyTableHeadContainer);
            initListeners();

            function initListeners() {
                const throttledResizeCallback = throttle(() => {
                    const theadChildNodesWidth = getTheadChildNodesWidth($table.find('thead'));
                    if (!isEqualsArrays(theadChildNodesWidth, lastTheadChildNodesWidth)) {
                        lastTheadChildNodesWidth = theadChildNodesWidth;
                        const $newCopyThead = getCopyThead($table.find('thead'));
                        $copyTableHeadContainer.find('thead').replaceWith($newCopyThead);
                        showHideThead(tableElement, $copyTableHeadContainer);
                        config.onAfterReDrawHead($copyTableHeadContainer);
                    }
                    resizeTableHeadContainer($responsiveContainer, $copyTableHeadContainer);
                }, config.optimization.resize);

                setInterval(throttledResizeCallback, config.optimization.check);

                $responsiveContainer
                    .on('scroll', () => {
                        translateCopyTableX($responsiveContainer, $copyTableHeadContainer.find('table'));
                        showHideThead(tableElement, $copyTableHeadContainer);
                    });
                $thead
                    .on('DOMSubtreeModified', () => {
                        throttledResizeCallback();
                    });
                $scrollContainer
                    .on('resize', throttledResizeCallback)
                    .on('scroll', () => {
                        showHideThead(tableElement, $copyTableHeadContainer);
                    });
                if (!$.isWindow($scrollContainer[0])) {
                    $(window)
                        .on('resize', throttledResizeCallback)
                        .on('scroll', () => {
                            showHideThead(tableElement, $copyTableHeadContainer);
                        });
                }
            }
        });
    };
}(jQuery));
