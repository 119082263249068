import * as Sentry from '@sentry/browser';

let instance = null;

/**
 * https://docs.sentry.io/error-reporting/capturing/?platform=javascript
 */
export class SentryInstance {
    constructor() {
        if (instance === null) {
            this.dsn = 'https://4bd8daaad52441abb5786deb332597a0@o329153.ingest.sentry.io/5306728';
            this.production = process.env.NODE_ENV === 'production';
            if (this.production) {
                Sentry.init({dsn: this.dsn});
            }
            instance = this;
        }
        return instance;
    }

    captureMessage(messageStr) {
        if (this.production) {
            Sentry.captureMessage(messageStr);
        }
    }

    captureException(exception, contextObject) {
        if (this.production) {
            Sentry.captureException(exception, contextObject);
        }
    }
}
