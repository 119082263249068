import '@js/plugins/headFixedTable';

export default function initHeadFixedTables($selector) {
    const screenWidth = $(window).width();
    let top = 83
    if (screenWidth > 1200) {
        top = 87;
    }
    $selector
        .fixedHeader({
            zIndex: 100,
            top: top,
            fixedContainerClasses: 'animated fadeInDown',
            fixedContainerTableClasses: 'table table-bordered table-striped table-hover head-fixed-table ranking-table',
            responsiveContainer($table) {
                const $responsive = ($table.closest('.table-responsive'));
                if ($responsive.length > 0) {
                    return $table.closest('.table-responsive');
                }
                return $table.parent();
            },
            onAfterReDrawHead($copyTableHeadContainer) {
                $copyTableHeadContainer.find('[data-toggle="tooltip"]').tooltip();
            },
        });
}
