import {SentryInstance} from '@js/sentry';
import initPageUpButton from '@js/components/pageUpButton';
import initTooltips from '@js/components/tooltip';
import initHeadFixedTables from '@js/components/headFixedTable';

new SentryInstance();

function initUrlThrottle() {
    const currentRequests = {};
    $.ajaxPrefilter((options, originalOptions, jqXHR) => {
        if (currentRequests[options.url]) {
            currentRequests[options.url].abort('requestWasSend');
        }
        currentRequests[options.url] = jqXHR;
    });
}

$(() => {
    initPageUpButton($('#pageUp'));
    initUrlThrottle();
    initTooltips($('[data-toggle="tooltip"]'));
    appendCsrfToken();
    initHeadFixedTables($('table.head-fixed-table'));
});

function appendCsrfToken() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('head meta[name="csrf-token"]').attr('content'),
        },
    });
}
