(function ($) {
    $.backUpButtonContainer = $.backUpButtonContainer || [];

    const debounce = function (func, threshold, execAsap) {
        let timeout;
        return function debounced() {
            const obj = this;
            const args = arguments;

            function delayed() {
                if (!execAsap) {
                    func.apply(obj, args);
                }
                timeout = null;
            }

            if (timeout) {
                clearTimeout(timeout);
            } else if (execAsap) {
                func.apply(obj, args);
            }
            timeout = setTimeout(delayed, threshold || 300);
        };
    };

    function backUpButtonContainer() {
        this.container = null;
        this.init = init.bind(this);

        const self = this;

        function init(container, params) {
            return new Promise((resolve) => {
                self.container = container;
                initListeners.call(this);
                self.config = $.extend(self.config, params);
                resolve(this.container);
            });
        }

        function checkScroll() {
            if ($(window).scrollTop() > 300) {
                self.container.addClass('show');
            } else {
                self.container.removeClass('show');
            }
        }

        function initListeners() {
            $(window).scroll(debounce(checkScroll, 150));
            $(window).scroll();
            this.container
                .on('click', (e) => {
                    e.preventDefault();
                    $('html, body')
                        .animate({scrollTop: 0}, '300');
                });
        }
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    const methods = {
        init(params = {}) {
            let backUpButtonContainerId;
            if (this.attr('id')) {
                backUpButtonContainerId = this.attr('id');
            } else {
                do {
                    var randomId = getRandomInt(1000000, 9999999);
                    var isset = typeof $.backUpButtonContainer[randomId] !== 'undefined';
                } while (isset);
                $(this).attr('id', randomId);
                backUpButtonContainerId = randomId;
            }
            $.backUpButtonContainer[backUpButtonContainerId] = new backUpButtonContainer();
            return $.backUpButtonContainer[backUpButtonContainerId].init(this, params);
        },
    };

    $.fn.backUpButton = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        }
        if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }
        throw `Метод ${method} не найден`;
    };
}(jQuery));
